import {
    ref,
    reactive,
    toRefs,
    computed,
    watch,
    defineComponent,
    onMounted,
    getCurrentInstance,
    provide,
    nextTick,
    inject
} from 'vue';
const QuotationCardHelper = defineComponent({
    name:'QuotationCard',
    title:'报价单',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dialogInst = inject('dialogInst');
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            percentFlag:false,
            hasPriceConfig:false,
            moduleType:'driver',
            refMap:new Map(),
            cityData:[],
            areaData:[],
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: true,
                saveConfirm:true,
                saveConfirmContent:'保存将删除之前配置好的报价信息，确定保存吗？',
                details:['startDistance','reNewDistance','reNewItemNum','reNewItemWeight','reNewItemSize','reNewPointNum'],
                modelPath: "/customerQuotation"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                name: [
                    {required: true, message: "请输入报价单名称", trigger: "blur" }
                ],
                cityId: [
                    {required: true, message: "请选择城市", trigger: "blur" }
                ],
                flag: [
                    {required: true, message: "请选择报价类型", trigger: "blur" }
                ]
            }
        })
        onMounted(()=>{
            nextTick(()=>{
                let from=proxy.engine.engineParams.from;
                if(from && 'saleBill'==from){//从销售单里面查看选择的报价单
                    dataObj.moduleType=proxy.engine.engineParams.moduleType;
                }else{//从客户报价单列表、承运商报价单列表查看报价单详情或者新增都会带入对应的moduleType
                    dataObj.moduleType=proxy.engine.engineParams.queryParam.moduleType;
                }
                if('driver'==dataObj.moduleType){
                    dialogInst.dialogOptions.title="承运商报价单";
                }
                if('customer'==dataObj.moduleType){
                    dialogInst.dialogOptions.title="客户报价单";
                }
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'cityId'==params.comboId){
                    return dataObj.cityData;
                }
                if(params && 'flag'==params.comboId){
                     return [{value:0,label:'标准产品'},{value:1,label:'非标产品'}]
                }
            }
        })
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType) => {
                return {
                    detailParam: {
                        // title:QuotationCardHelper.title,
                        showTitle:false,
                        readOnly:dataObj.disabled,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: "/customerQuotation/detail?t="+Math.random()*1000
                    }
                }
            }
        })
        const buildCityData=async ()=>{
            dataObj.cityData = await utils.$$api.getCitiesByChannelId();
        }
        const buildAreaData=async (cityId)=>{
            dataObj.areaData = await utils.$$api.getAreasByCity({cityId:cityId});
        }
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue,selectId)=>{
            if('cityId'==selectId){
                dataObj.areaData=[];
                await buildAreaData(newValue);
                dataObj.form.fromAreaId='无';
                dataObj.form.toPlaceSel=[];
            }
            if('flag'==selectId){}
        }
        //卡片发送请求到后台前，可以设置发送到后台的参数，这里主要是判断，如果从销售单查看报价单的话，需要传一个参数给后台，让后台不返回相关按钮
        const setParam=(cardEngineInst)=>{
            let engineParams=proxy.engine.engineParams;
            //如果是销售单查看报价单，则from直接在engineParams下面
            let from=engineParams.from;
            //如果是运力配置打开承运商报价单列表，再由承运商报价单列表的编号调用列表引擎打开报价单，这个时候from其实是由承运商报价单列表的查询参数传过来的，值为ylpz
            if(!from && engineParams.queryParam)from=engineParams.queryParam.from;
            if(from){
                return {from:from}
            }else{
                return{};
            }
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            if(data.data.type==1 && data.data.flag==1){//type==1表示承运商报价单，flag==1表示承运商报价单按客户报价单百分比结算
                dataObj.percentFlag=true;
                dataObj.form.percent=parseFloat(data.data.percent);
            }
            dataObj.cxList=data.data.cxList;
            await buildCityData();
            if('/load'==addOrLoad){
                dataObj.hasPriceConfig=data.hasPriceConfig;
                await buildAreaData(data.data.cityId);
            }
            if(dataObj.hasPriceConfig){dataObj.compParams.saveConfirm=true;}else{dataObj.compParams.saveConfirm=false;}
            //有可能是从销售单处查看的报价单，所以要把相关项控制为不可用
            if(proxy.engine.engineParams.from || ('/load'==addOrLoad && data.disabled==true)){
                dataObj.disabled=true;
                await dataObj.refMap.get('startDistance').setReadOnly(true);
                await dataObj.refMap.get('reNewDistance').setReadOnly(true);
                await dataObj.refMap.get('reNewItemNum').setReadOnly(true);
                await dataObj.refMap.get('reNewItemWeight').setReadOnly(true);
                await dataObj.refMap.get('reNewItemSize').setReadOnly(true);
                await dataObj.refMap.get('reNewPointNum').setReadOnly(true);
            }
        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType,options)=>{
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(detailType, row, index)=>{
            let data=dataObj.refMap.get(detailType).getDetailData();
            let title;
            switch (detailType) {
                case 'startDistance':title='起步价里程';break;
                case 'reNewDistance':title='续费里程';break;
                case 'reNewItemNum':title='续费件数';break;
                case 'reNewItemWeight':title='续费重量';break;
                case 'reNewItemSize':title='续费体积';break;
                case 'reNewPointNum':title='续费点位数';break;
            }
            if(data.length-1==index){//最后一行结束编辑
                if(row['F_END'] && row['F_END']<=row['F_BEGAN']){
                    proxy.$message(title+'第'+(index+1)+'行结束值不能小于开始值');
                    return false;
                }
            }else{
                if(row['F_END']<=row['F_BEGAN']){
                    proxy.$message(title+'第'+(index+1)+'行结束值不能小于开始值');
                    return false;
                }else {
                    let updateRow=data[index+1];
                    updateRow['F_BEGAN']=row['F_END'];
                    dataObj.refMap.get(detailType).setRowData(updateRow,(index+1));
                }
            }
            //起步价最后一行结束编辑需要单独判断一下，让他和续费里程第一行的开始里程保持一致
            if('startDistance'==detailType && (data.length-1)==index){
                //如果同时设置了起步里程和续费里程，则判断两者是否衔接
                let reNewDistanceData=dataObj.refMap.get('reNewDistance').getDetailData();
                if(data.length>0 && reNewDistanceData.length>0){
                    reNewDistanceData[0]['F_BEGAN']=data[index]['F_END'];
                    dataObj.refMap.get('reNewDistance').setRowData(reNewDistanceData[0],(0));
                }
            }
            return true;
        }

        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //验证明细表格数据是否合法
            if(dataObj.percentFlag){
                if(dataObj.form.percent==0){
                    utils.$$tools.warning({message:"请输入按客户结算费用的百分比"});
                    return false;
                }
                dataObj.form.flag=1;//承运商报价单
            }else{
                dataObj.form.percent='';//如果没有打开按客户结算费用百分比计算，那么不管是否是承运商报价单，都把百分比清空
                //验证装货点和卸货点，要么都不选要么都选
                let fromAreaId=dataObj.form.fromAreaId;
                let toPlaceSel=dataObj.form.toPlaceSel;
                if(utils.$$str.isNotEmpty(fromAreaId) && fromAreaId!='无' && toPlaceSel.length==0){
                    utils.$$tools.warning({message:"请选择卸货点或者清除装货点"});
                    return false;
                }
                if(toPlaceSel.length>0 && (utils.$$str.isEmpty(fromAreaId) || fromAreaId=='无')){
                    utils.$$tools.warning({message:"请选择装货点或者清除卸货点"});
                    return false;
                }
                if(!checkValidate())return false;//如果没有输入任何有效数据，直接返回
                //验证起步价设置与否，数据的完整性
                let num=dataObj.form.num,weight=dataObj.form.weight,size=dataObj.form.size,pointNum=dataObj.form.pointNum,cxSel=dataObj.form.cxSel;
                let startDistanceLen=dataObj.refMap.get('startDistance').getDetailData().length;
                if((num>0 || weight>0 || size>0 || pointNum>0 || startDistanceLen>0) && toPlaceSel.length==0 && cxSel.length==0){
                    utils.$$tools.warning({message:"配置了起步价就必须要配置车型或者选择装/卸货点"});
                    return false;
                }
            }
            //设置报价单类别
            if(dataObj.moduleType=='customer')dataObj.form.type=0;
            else dataObj.form.type=1;

            let startDistanceData=dataObj.refMap.get('startDistance').getDetailData();
            let result=checkDetailData(startDistanceData,'起步价里程',1);

            let reNewDistanceData=dataObj.refMap.get('reNewDistance').getDetailData();
            if(startDistanceData.length>0 && detailHasWqd(startDistanceData)){
                if(reNewDistanceData.length>0){
                    utils.$$tools.warning({message:'起步价里程已设置无穷大，所以不需要设置续费里程'});
                    return false;
                }
            }else{//如果起步里程未设置无穷大，那么续费里程就必须设置无穷大
                if(startDistanceData.length>0 && (reNewDistanceData.length==0 || !detailHasWqd(reNewDistanceData))){
                    utils.$$tools.warning({message:'续费里程必须设置无穷大'});
                    return false;
                }
            }

            let startDistanceMaxEnd=startDistanceMax(startDistanceData);
            if(result)result=checkDetailData(reNewDistanceData,'续费里程',2,startDistanceMaxEnd);

            let reNewItemNumData=dataObj.refMap.get('reNewItemNum').getDetailData();
            if(result)result=checkDetailData(reNewItemNumData,'续费件数',3,dataObj.form.num);

            let reNewItemWeightData=dataObj.refMap.get('reNewItemWeight').getDetailData();
            if(result)result=checkDetailData(reNewItemWeightData,'续费重量',4,dataObj.form.weight);

            let reNewItemSizeData=dataObj.refMap.get('reNewItemSize').getDetailData();
            if(result)result=checkDetailData(reNewItemSizeData,'续费体积',5,dataObj.form.size);

            let reNewPointNumData=dataObj.refMap.get('reNewPointNum').getDetailData();
            if(result)result=checkDetailData(reNewPointNumData,'续费配送点位',6,dataObj.form.pointNum);

            return result;
        }
        //保存之后
        const afterSaveHandler=(res,contentName,cardEngineInst)=>{
            if(!dataObj.percentFlag)proxy.$message({showClose: true, message: '保存成功，请配置报价', type: 'info'});
            return true;
        }
        //检查起步价里程中是否有无穷大，如果有，则不能设置续费里程
        const detailHasWqd=(data)=>{
            for(let i=0;i<data.length;i++) {
                let end = data[i]['F_END'];
                if (!end)return true;
            }
            return false;
        }
        //得到起步价结束里程的最大值
        const startDistanceMax=(data)=>{
            let maxEnd=0;
            for(let i=0;i<data.length;i++) {
                let end = data[i]['F_END'];
                if(!end)return '∞';
                end=parseInt(end);
                if(end>maxEnd)maxEnd=end;
            }
            return maxEnd;
        }
        //保存前验证是否有输入效数据
        const checkValidate=()=>{
            let num=dataObj.form.num,weight=dataObj.form.weight,size=dataObj.form.size,pointNum=dataObj.form.pointNum;
            let flag1=dataObj.refMap.get('startDistance').getDetailData().length==0;
            let flag2=dataObj.refMap.get('reNewDistance').getDetailData().length==0;
            let flag3=dataObj.refMap.get('reNewItemNum').getDetailData().length==0;
            let flag4=dataObj.refMap.get('reNewItemWeight').getDetailData().length==0;
            let flag5=dataObj.refMap.get('reNewItemSize').getDetailData().length==0;
            let flag6=dataObj.refMap.get('reNewPointNum').getDetailData().length==0;
            if(num==0 && weight==0 && size==0 && pointNum==0
                && flag1 && flag2 && flag3 && flag4 && flag5 && flag6){
                utils.$$tools.warning({message:'亲！该报价单没有输入任何有效数据哦。'});
                return false;
            }
            return true;
        }
        //保存前验证明细表格输入是否合法
        const checkDetailData=(data,noteMsg2,type,minNum)=>{
            let flag=false;
            let len=data.length;
            for(let i=0;i<len;i++){
                let began=data[i]['F_BEGAN'];//当前行的began
                began=parseInt(began);
                //--------------------为了保险起见，在保存的时候还是验证一下数据的连续性
                if(len>1 && i!=0){//如果表格数据大于1行而且当前循环行不是第1行
                    let preEnd=data[i-1]['F_END'];
                    if(began!=preEnd){//当前行的began和前一行的end不一致
                        utils.$$tools.warning({message:noteMsg2+'第'+(i)+'行结束值和'+(i+1)+'行开始值不一致，数据不连续'});
                        return false;
                    }
                }
                //--------------------为了保险起见，在保存的时候还是验证一下数据的连续性
                let end=data[i]['F_END'];
                if(!end)flag=true;//存在无穷大
                if(end!='∞' && began>=parseInt(end)){
                    utils.$$tools.warning({message:noteMsg2+'第'+(i+1)+'行结束值必须大于开始值'});
                    return false;
                }
            }
            if(type!=1 && type!=2 && (minNum>0 || data.length>0) && !flag){//type=2是续费里程，在前面已经判断了，续费里面的其他项如果在起步价中有设置，那么续费就必须设置，且必须包含无穷大，这样在配载那里就不怕超量了
                utils.$$tools.warning({message:noteMsg2+'必须设置，且必须包含无穷大'});
                return false;
            }
            return true;
        }
        const beforeAddHandler=(detailType, row)=>{
            let data=dataObj.refMap.get(detailType).getDetailData();
            if(data.length==0){
                if('startDistance'==detailType){
                    row['F_BEGAN']=0;
                }else if('reNewDistance'==detailType){
                    let startDistanceData=dataObj.refMap.get('startDistance').getDetailData();
                    let startDistanceMaxEnd=startDistanceMax(startDistanceData);
                    if(startDistanceMaxEnd=='∞'){
                        proxy.$message('起步价里程已经配置了无穷大，不允许配置续费里程');
                        return false;
                    }else{
                        row['F_BEGAN']=startDistanceMaxEnd;
                    }
                }else if('reNewItemNum'==detailType){
                    row['F_BEGAN']=dataObj.form.num;
                }else if('reNewItemWeight'==detailType){
                    row['F_BEGAN']=dataObj.form.weight;
                }else if('reNewItemSize'==detailType){
                    row['F_BEGAN']=dataObj.form.size;
                }else if('reNewPointNum'==detailType){
                    row['F_BEGAN']=dataObj.form.pointNum;
                }
                return true;
            }
            if(!data[data.length-1]['F_END'])return false;//如果没有输入结束，那么就不允许新增了
            //可以多个无穷大
            if(utils.$$str.isNotEmpty(data[data.length-1]['F_END'])){
                row['F_BEGAN']=data[data.length-1]['F_END'];
            }
            return true;
        }
        const afterDelHandler=(detailType, row, index)=>{//row:被删除的行 index：被删除的行索引
            let data=dataObj.refMap.get(detailType).getDetailData();
            if(data.length>0){//删除之后，表格还有值，则需要重新设置删除行前后的began，让数据保持连续性
                if(data.length==index){//删除的是最后一行
                    if('startDistance'==detailType){//如果是删除起步价中的里程最后一行，则需要改变续费中的第一行began
                        let reNewDistanceData=dataObj.refMap.get('reNewDistance').getDetailData();
                        if(reNewDistanceData.length>0){
                            let reNewFist=reNewDistanceData[0];
                            reNewFist['F_BEGAN']=data[data.length-1]['F_END'];
                            dataObj.refMap.get('reNewDistance').setRowData(reNewFist,0);
                        }
                    }
                }else{//不是删除最后行，则把删除行的began拿给下一行的began
                    let updateRow=data[index];//删除行的下一行
                    updateRow['F_BEGAN']=row['F_BEGAN'];//把删除行的Began拿给下一行的began
                    dataObj.refMap.get(detailType).setRowData(updateRow,index);
                }
            }else{//删除之后，表格没有数据了
                if('startDistance'==detailType){//如果是删除起步价中的里程
                    let reNewDistanceData=dataObj.refMap.get('reNewDistance').getDetailData();
                    if(reNewDistanceData.length>0){//起步价已经没有数据了，又设置过续费，则需要把续费从0开始
                        let reNewFist=reNewDistanceData[0];
                        reNewFist['F_BEGAN']=0;
                        dataObj.refMap.get('reNewDistance').setRowData(reNewFist,0);
                    }
                }
            }
        }
        //清除装货点单选按钮事件
        const fromAreaIdSelHandler=()=>{
            dataObj.form.fromAreaId='无';
            dataObj.form.toPlaceSel=[];
        }
        const channelClaimHandler=async ()=>{
            await utils.$$lghdUtils.buildClaimDialog({proxy:proxy});
        }
        const sureHandler=async (modelInst)=>{
            let res=await utils.$$lghdUtils.claimDialogSure({modelInst:modelInst,proxy:proxy,url: dataObj.compParams.modelPath + "/custom",id:dataObj.form.id});
            return res;
        }

        const configQuotationHandle=()=>{
            //如果从销售单过来，可以考虑设置canEdit为false传给弹出框
            let canEdit=true;
            if(proxy.engine.engineParams.from)canEdit=false;
            utils.$$lghdUtils.buildQuotationPriceConfigDialog({id:dataObj.form.id,proxy:proxy,canEdit:canEdit});
        }
        const startHandler=async()=>{
            utils.$$lghdUtils.startHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const stopHandler=async()=>{
            utils.$$lghdUtils.stopHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        //起步价中件数、重量、体积、点位数发生改变事件 设置续费中件数、重量、体积、点位数表格第一行开始值
        const configRenewFist=(type,val)=>{
            let data=dataObj.refMap.get(type).getDetailData();
            if(data.length>0 && data[0]['F_BEGAN']!=val){
                data[0]['F_BEGAN']=val;
                dataObj.refMap.get(type).setRowData(data[0],0);
            }
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,cardDetailParam,beganEdit,beforeEndEdit,beforeOpen,beforeSaveHandler,afterSaveHandler,
            afterDelHandler,beforeAddHandler,fromAreaIdSelHandler,channelClaimHandler,sureHandler,configQuotationHandle,startHandler,stopHandler,
            setParam,configRenewFist
         //,gridLoaded
        }
    }
});
export default QuotationCardHelper;